import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ProductSearchService } from 'src/app/service/product-search.service';
import * as _ from 'lodash';

@Component({
	selector: 'crf-ca-product-selector-single',
	templateUrl: './product-selector-single.component.html',
	styleUrl: './product-selector-single.component.scss'
})
export class ProductSelectorSingleComponent implements OnInit {
	isonlyBrand: boolean = false;
	isManufacturer: boolean = false;
	tableLoader: boolean = false;
	enableSaveButton: boolean = false;

	isSelected: boolean = false;
	suggesstionSearch: boolean = false;
	isReset: boolean = false;
	activeAccordion: string = '';
	optedSelection: any = [];
	sortedResponseTotal = {
		TOTAL: {},
		MAJOR: {},
		DEPT: {},
		CATGRP: {},
		CAT: {},
		SUBCAT: {},
		SEG: {},
		BRDOWNHGH: {},
		BRDOWNLOW: {},
		BRDHGH: {},
		BRANDFAMILY: {},
		BRDLOW: {},
		BRAND: {}
	};
	sortedResponseTemp = {
		TOTAL: {},
		MAJOR: {},
		DEPT: {},
		CATGRP: {},
		CAT: {},
		SUBCAT: {},
		SEG: {},
		BRDOWNHGH: {},
		BRDOWNLOW: {},
		BRDHGH: {},
		BRANDFAMILY: {},
		BRDLOW: {},
		BRAND: {}
	};
	accordianView = {
		TOTAL: { isOpen: false, selectedOption: null },
		MAJOR: { isOpen: false, selectedOption: null },
		DEPT: { isOpen: false, selectedOption: null },
		CATGRP: { isOpen: false, selectedOption: null },
		CAT: { isOpen: false, selectedOption: null },
		SUBCAT: { isOpen: false, selectedOption: null },
		SEG: { isOpen: false, selectedOption: null },
		BRDOWNHGH: { isOpen: false, selectedOption: null },
		BRDOWNLOW: { isOpen: false, selectedOption: null },
		BRDHGH: { isOpen: false, selectedOption: null },
		BRANDFAMILY: { isOpen: false, selectedOption: null },
		BRDLOW: { isOpen: false, selectedOption: null },
		BRAND: { isOpen: false, selectedOption: null }
	};

	suggestions: string[] = [];

	searchData: {};

	keyToSearch: string = '';
	hierrachyTypeToSearch: string = 'ALL';
	isSuggestions: boolean = false;
	isListAvailable: boolean = false;

	matchText: string = '';
	selectedProduct: string;

	productPerformanceMandatoryText: string;
	brandMandatoryTextForManufacturer: string;
	categoryMandatoryTextForCDT: string;

	@Input() selectorType: number;
	@Input() configId: number;
	@Input() fromMainDashboard: boolean;
	@Output() onClose = new EventEmitter();
	@Output() productUpdateFromMD = new EventEmitter();
	@Output() selectedProuctMain = new EventEmitter();
	@Output() updateReport: EventEmitter<any> = new EventEmitter<any>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private utilityService: UtilityService,
		private productSearchService: ProductSearchService
	) {}

	ngOnInit() {
		// this.isManufacturer = # TO-DO fetch from service
		this.fixedValues();
		this.searchProduct();
		this.productPerformanceMandatoryText = this.configId === 2 ? ' Any two levels Mandatory' : '';
		this.brandMandatoryTextForManufacturer =
			this.configId === 3 || this.configId === 8
				? ' With Brand, Product selection is Mandatory'
				: '';
		this.categoryMandatoryTextForCDT =
			this.configId === 14 ? ' Category selection is Mandatory' : '';
	}

	fixedValues() {
		this.optedSelection = [
			{ key: 'TOTAL_BUSINESS', value: '', alias_name: 'TOTAL', payloadField: 'totalBusiness' },
			{ key: 'MAJOR_BUSINESS', value: '', alias_name: 'MAJOR', payloadField: 'majorBusiness' },
			{ key: 'DEPARTMENT', value: '', alias_name: 'DEPT', payloadField: 'department' },
			{ key: 'CATEGORY_GROUP', value: '', alias_name: 'CATGRP', payloadField: 'categoryGroup' },
			{ key: 'CATEGORY', value: '', alias_name: 'CAT', payloadField: 'category' },
			{ key: 'SUBCATEGORY', value: '', alias_name: 'SUBCAT', payloadField: 'subCategory' },
			{ key: 'SEGMENT', value: '', alias_name: 'SEG', payloadField: 'segment' },
			{
				key: 'BRAND_OWNER_HIGH',
				value: '',
				alias_name: 'BRDOWNHGH',
				payloadField: 'brandOwnerHigh'
			},
			{ key: 'BRAND_OWNER_LOW', value: '', alias_name: 'BRDOWNLOW', payloadField: 'brandOwnerLow' },
			{ key: 'BRAND_HIGH', value: '', alias_name: 'BRDHGH', payloadField: 'brandHigh' },
			{ key: 'BRAND_FAMILY', value: null, alias_name: 'BRDFAMILY', payloadField: 'brandFamily' },
			{ key: 'BRAND_LOW', value: '', alias_name: 'BRDLOW', payloadField: 'brandLow' },
			{ key: 'BRAND', value: '', alias_name: 'BRAND', payloadField: 'brand' }
		];
	}

	//when the user starts tying in the text box(enabling suggestion Search)
	productSearch(searchString: string, isEnterKey: boolean) {
		this.keyToSearch = searchString;
		if (isEnterKey) {
			this.suggestions = [];
			this.isSuggestions = false;
			this.searchProduct();
		} else {
			this.sendSuggestionReq(searchString);
		}
	}

	clearProductSearch() {
		this.keyToSearch = '';
		this.suggesstionSearch = false;
		this.searchProduct();
	}

	// Load more data on scroll
	loadMore(fetchData: boolean, key) {
		if (this.utilityService.isValid(this.searchData[key]) && fetchData) {
			const count = this.searchData[key][0].length + 20;
			// this.searchData[key][0] = [...(this.sortedResponseTotal[key]?.slice(0, count) || [])];
			this.searchData[key][0] = [...this.sortedResponseTotal[key]?.slice(0, count)];
		}
	}

	//when the user opted any one option among the list
	suggetionChoosen(item: string) {
		this.keyToSearch = item;
		this.suggesstionSearch = true;
		this.searchProduct();
	}

	searchProduct() {
		this.showHideLoader(true);
		setTimeout(() => {
			this.suggestions = [];
		}, 10);
		let reqBody = {
			searchType: this.isSelected,
			dashboardId: this.configId,
			hierrachyType: 'ALL',
			selectedVal: this.getSearchString(),
			fromMainDashboard: this.fromMainDashboard
		};
		this.productSearchService.getSearchList(reqBody).subscribe(response => {
			if (this.utilityService.isValid(response)) {
				const loadbyCount = 50;
				this.isListAvailable = true;
				this.isSuggestions = false;
				this.sortedResponseTotal = _.cloneDeep(response);

				this.showHideLoader(false);

				for (const [key] of Object.entries(response)) {
					if (!_.isUndefined(response[key])) {
						this.sortedResponseTemp[key] = [
							_.take(response[key], loadbyCount),
							response[key].length
						];
					}
				}

				this.sortedResponseTotal = _.omitBy(this.sortedResponseTotal, _.isEmpty);
				this.searchData = _.omitBy(this.sortedResponseTemp, _.isEmpty);

				this.matchText = this.utilityService.isValid(this.keyToSearch)
					? "Matches Found  : '" + this.keyToSearch + "'"
					: '';
				this.keyToSearch = '';
			} else {
				this.showHideLoader(false);
			}
		});
	}

	getSearchString() {
		let keywordToSearch = this.utilityService.isValid(this.keyToSearch)
			? this.keyToSearch
			: 'DISPLAYALL';
		let exactMatchValue = 'false';
		let optedValues = {};
		let extOptedValues = {
			totalBusiness: '',
			majorBusiness: '',
			department: '',
			categoryGroup: '',
			category: '',
			subCategory: '',
			segment: '',
			brandOwnerHigh: '',
			brandOwnerLow: '',
			brandHigh: '',
			brandfamily: '',
			brandLow: '',
			brand: ''
		};

		if (this.utilityService.isValid(this.keyToSearch) && this.suggesstionSearch) {
			exactMatchValue = 'true';
		}

		optedValues = {
			searchString: keywordToSearch,
			exactMatch: exactMatchValue
		};

		if (this.isSelected) {
			this.optedSelection.forEach(item => {
				let keyName = this.dashedToCamelCase(item.key);
				extOptedValues[keyName] = this.utilityService.isValid(item.value) ? item.value : '';
			});
			optedValues = { ...optedValues, ...extOptedValues };
		}

		return optedValues;
	}

	dashedToCamelCase(str) {
		if (str !== 'SUBCATEGORY') {
			return str.toLowerCase().replace(/_(.)/g, function (match, chr) {
				return chr.toUpperCase();
			});
		} else {
			return 'subCategory';
		}
	}

	showHideLoader(value: boolean) {
		this.tableLoader = value;
	}

	sendSuggestionReq(searchString: string) {
		// Will not send search until user has entered 2 characters
		if (searchString.length < 3) {
			this.suggestions = [];
			return;
		}
		if (searchString !== '') {
			this.productSearchService.getSuggestionSearch(this.configId, searchString).subscribe({
				next: (response: any) => {
					this.suggestions = this.utilityService.isValid(response.product) ? response.product : [];
					this.isSuggestions = true;
				},
				error: error => {
					this.suggestions = [];
					this.isSuggestions = false;
				}
			});
		}
	}

	selectItem(type, fieldValue) {
		// TOTAL MAJOR
		const index = _.findIndex(this.optedSelection, function (optobject) {
			return optobject.alias_name === type;
		});
		this.enableSaveButton = false;
		this.isSelected = true;
		if (fieldValue !== -1) {
			// when unselect radio button
			if (fieldValue === this.optedSelection[index].value) {
				this.optedSelection[index].value = '';
				this.accordianView[type].selectedOption = null;
				this.isReset = true;
			} else {
				this.optedSelection[index].value = fieldValue;
				this.accordianView[type].selectedOption = fieldValue;
				this.isReset = false;
			}
		} else {
			// when click on reset icon
			this.optedSelection[index].value = '';
			this.accordianView[type].selectedOption = null;
			this.isReset = true;
		}

		let selectionCount = 0;
		if (this.utilityService.isValid(this.optedSelection[0].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[0];
		}
		if (this.utilityService.isValid(this.optedSelection[1].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[1];
		}
		if (this.utilityService.isValid(this.optedSelection[2].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[2];
		}
		if (this.utilityService.isValid(this.optedSelection[3].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[3];
		}
		if (this.utilityService.isValid(this.optedSelection[4].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[4];
		}
		if (this.utilityService.isValid(this.optedSelection[5].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[5];
		}
		if (this.utilityService.isValid(this.optedSelection[6].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[6];
		}
		if (this.utilityService.isValid(this.optedSelection[7].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[7];
		}
		if (this.utilityService.isValid(this.optedSelection[8].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[8];
		}
		if (this.utilityService.isValid(this.optedSelection[9].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[9];
		}
		if (this.utilityService.isValid(this.optedSelection[10].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[10];
		}
		if (this.utilityService.isValid(this.optedSelection[11].value)) {
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[11];
		} //Only for Case dashboards BRAND level};
		if (this.utilityService.isValid(this.optedSelection[12].value) && selectionCount === 0) {
			this.optedSelection[0].value = 'TOTAL FOOD/CONSUMABLES/OTC';
			selectionCount = selectionCount + 1;
			this.selectedProduct = this.optedSelection[0];
		}

		this.isSelected = selectionCount > 0; //Based on Queid  to enable or  disable the APPLY and Save buttons

		if (this.configId === 14) {
			this.enableSaveButton = this.optedSelection[4].value;
		} else if (this.configId === 2) {
			// any two levels mandatory for Product Performance
			this.enableSaveButton = selectionCount > 1;
		} else if (
			(this.configId === 3 || this.configId === 8) &&
			this.utilityService.isValid(this.optedSelection[11].value) &&
			this.isManufacturer
		) {
			// In Case Dashboards when selected  ONLY brand
			this.enableSaveButton = false;
			this.isonlyBrand = selectionCount === 1;
			this.enableSaveButton = selectionCount > 1;
		} else if (
			(this.configId === 0 ||
				this.configId === 1 ||
				this.configId === 3 ||
				this.configId === 4 ||
				this.configId === 5 ||
				this.configId === 6 ||
				this.configId === 7 ||
				this.configId === 8 ||
				this.configId === 9 ||
				this.configId === 11 ||
				this.configId === 12) &&
			selectionCount > 0
		) {
			this.enableSaveButton = true;
		} else if (
			this.utilityService.isValid(this.optedSelection[2].value) ||
			this.utilityService.isValid(this.optedSelection[3].value) ||
			this.utilityService.isValid(this.optedSelection[4].value)
		) {
			this.enableSaveButton = true;
		} else {
			this.isonlyBrand = false;
			this.enableSaveButton = false;
		}
		this.searchProduct();
	}

	clearUserSelection() {
		this.isListAvailable = false;
		this.tableLoader = true;
		this.activeAccordion = '';
		this.searchData = {};
		this.enableSaveButton = false;
		this.isSelected = false;
		this.keyToSearch = '';
		this.selectedProduct = '';
		this.optedSelection = [];
		this.suggestions = [];
		this.hierrachyTypeToSearch = 'ALL';
		for (const item in this.accordianView) {
			this.accordianView[item].selectedOption = null;
		}
		this.fixedValues();
		this.searchProduct();
	}

	applyUserSelection(saveDefault: boolean) {
		this.updateReport.emit({
			optedValues: this.optedSelection,
			selectedProduct: this.selectedProduct,
			saveDefault: saveDefault
		});
		this.onCloseDialog();
	}

	saveUserSelection() {
		this.applyUserSelection(true);
		let optedValuesSave =
			'?department=' +
			(this.utilityService.isValid(this.optedSelection[2]) ? this.optedSelection[2].value : '') +
			'&categorygroup=' +
			(this.utilityService.isValid(this.optedSelection[3]) ? this.optedSelection[3].value : '') +
			'&category=' +
			(this.utilityService.isValid(this.optedSelection[4]) ? this.optedSelection[4].value : '') +
			'&subcategory=' +
			(this.utilityService.isValid(this.optedSelection[5]) ? this.optedSelection[5].value : '') +
			'&segment=' +
			(this.utilityService.isValid(this.optedSelection[6]) ? this.optedSelection[6].value : '') +
			'&brandownerhigh=' +
			(this.utilityService.isValid(this.optedSelection[7])
				? this.optedSelection[7].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&brandownerlow=' +
			(this.utilityService.isValid(this.optedSelection[8])
				? this.optedSelection[8].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&brandhigh=' +
			(this.utilityService.isValid(this.optedSelection[9])
				? this.optedSelection[9].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&brandfamily=' +
			'&brandlow=' +
			(this.utilityService.isValid(this.optedSelection[11])
				? this.optedSelection[11].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&totalbusiness=' +
			(this.utilityService.isValid(this.optedSelection[0])
				? this.optedSelection[0].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&majorbusiness=' +
			(this.utilityService.isValid(this.optedSelection[1])
				? this.optedSelection[1].value.replace('%', '%25').replace('+', '%2B').replace('&', '%26')
				: '') +
			'&brand=' +
			(this.utilityService.isValid(this.optedSelection[12]) ? this.optedSelection[12].value : '') +
			'&level=';

		if (!this.fromMainDashboard) {
			this.productSearchService
				.saveUserSelection(this.configId, optedValuesSave)
				.subscribe(result => {});
		} else {
			let questionIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 13, 14];
			let checkIfAllSet = {};
			this.productUpdateFromMD.emit(false);
			questionIds.forEach(id => (checkIfAllSet[id] = false));
			for (let i = 0; i < questionIds.length; i++) {
				this.productSearchService
					.saveUserSelection(questionIds[i], optedValuesSave)
					.subscribe(response => {
						checkIfAllSet[questionIds[i]] = true;
						let allCompleted = Object.keys(checkIfAllSet).every(id => checkIfAllSet[id]);
						this.productUpdateFromMD.emit(allCompleted);
						this.selectedProuctMain.emit(this.selectedProduct);
					});
			}
		}
	}

	onCloseDialog() {
		this.activeAccordion = '';
		this.onClose.emit();
	}
}
