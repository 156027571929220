import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ColDef, GetDataPath, GridReadyEvent, SortDirection } from 'ag-grid-community';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { defaultColDef, icons, themeClass } from 'src/app/shared/model/column.config';
import { pageHoverTexts } from 'src/app/models/report-hovertexts.constant';
import { CustomIconComponent } from '../custom-icon/custom-icon.component';
import { ShareReportDashboardService } from 'src/app/reports/services/share-report-dashboard.service';
import { ShareReportService } from 'src/app/reports/services/share-report.service';
import { SelectorConfiguration } from 'src/app/models/report.model';
import { SELECTORS } from 'src/app/constants/report.constant';
import { SHARE_REPORT_CONSTANTS } from '../../constants/share-report.constant';
import { GridCellComponent } from 'src/app/shared/components/grid/grid-cell/grid-cell.component';

@Component({
	selector: 'crf-ca-product-comparison',
	templateUrl: './product-comparison.component.html',
	styleUrl: './product-comparison.component.scss'
})
export class ProductComparisonComponent implements OnInit {
	constructor(
		public shareReportDashboardService: ShareReportDashboardService,
		public utility: UtilityService,
		public shareReportService: ShareReportService
	) {
		this.upcColumns = SHARE_REPORT_CONSTANTS.BASE_FACT_LIST;
	}
	pageHoverTexts = pageHoverTexts;
	public themeClass: string = themeClass;
	public icons = icons;
	@Input() productData: Array<any>;
	@Input() childLevel: Array<any>;
	@Input() byLevelData: Array<any>;
	@Input() reportDownload: any;
	@Input() selectedPeriod: any;
	@Input() factDataNew: any;
	@Input() selectedFact: any;
	@Input() actualValue: any;
	@Output() sortDetails = new EventEmitter<any>();
	@Output() productDetails = new EventEmitter<any>();
	@Output() excelData = new EventEmitter<any>();
	@Output() excelProdData = new EventEmitter<any>();
	isLoading: boolean = true;
	upcColumns: any;
	productColumns: any;
	rowData: any = [];
	excel: any = [];
	colDefs: any;
	gridApi: GridReadyEvent;
	public defaultColDef: ColDef = {
		...defaultColDef,
		sortable: true,
		cellRendererParams: {
			suppressCount: true
		},
		cellRenderer: GridCellComponent
	};
	public autoGroupColumnDef: ColDef = {
		lockPosition: true,
		lockPinned: true,
		pinned: true,
		headerName: 'Products',
		field: 'productLevel',
		minWidth: 300,
		cellClass: 'gridProductName',
		suppressMovable: true,
		suppressColumnsToolPanel: true,
		sort: 'asc',
		sortingOrder: ['asc', 'desc', null],
		cellRendererParams: {
			suppressCount: true,
			onInspectClick: (params, subCategory) => {
				this.onSubCategorySelection(params, subCategory);
			}
		},
		cellRenderer: CustomIconComponent,
		onCellClicked: event => {}
	};
	public sortingOrder: SortDirection[] = ['desc', 'asc', null];
	selectors: SelectorConfiguration = {
		id: SELECTORS.FACT.ID,
		display: true,
		displayName: 'Fact',
		displayValue: 'Fact Selector',
		actualValue: [],
		tooltip: 'Choose the Fact you would like to show in this report',
		icon: 'chevron-down',
		displayDropdown: false,
		type: 'fact-selector',
		dropdownItems: [],
		isMultipleSelect: false
	};

	// Load column at initial load
	defaultColumns: any = ['Product'];

	ngOnInit(): void {
		if (this.productData) {
			this.isLoading = false;
		}
		this.upcColumns = this.utility.setDefaultColumnsPropertyByGkey(
			this.upcColumns,
			this.defaultColumns
		);
		this.getDefaultProduct();
		this.loadFactDropdown();
		this.colDefs = this.getFactColumnDefinition();
		this.excelData.emit(this.colDefs);
		this.loadProductData();
	}
	onGridReady(params: GridReadyEvent) {
		this.gridApi = params;
	}
	public groupDefaultExpanded = -1;
	public rowSelection: 'single';
	public getDataPath: GetDataPath = (data: any) => {
		return data.path; // path: "parent/child"
	};

	loadProductData() {
		let treeData = this.productData.map((data, index) => ({
			...data,
			rowId: index,
			bylevel: this.byLevelData
		}));

		let parentProductName: string;
		_.forEach(treeData, function (data) {
			if (data.hierarchy === 'parent') {
				parentProductName = data?.productLevel;
				data.path = [parentProductName];
			}
		});

		_.forEach(treeData, function (data) {
			if (data.hierarchy === 'child' && parentProductName) {
				data.path = [parentProductName, data.productLevel];
			}
		});

		this.rowData = [...treeData];
		this.excelProdData.emit(this.rowData);
	}

	/**
	 *
	 * @param params
	 * @param subCategory
	 * call subCategory details
	 */
	onSubCategorySelection(params: any, subCategory: any) {
		this.productDetails.emit({
			subCategory: subCategory,
			subCategoryLevel: params.node.level,
			params: params
		});
		this.isLoading = true;
		let expandedReqObject = this.childLevel[this.childLevel.length - 1];
		if (this.childLevel.length > 0) {
			this.shareReportDashboardService
				.getShareReportDashboardInfo(expandedReqObject, 'Product')
				.subscribe(value => {
					let productResponseData = value.responseData.ShareReportProductMetrics;
					if (!this.utility.isValid(productResponseData)) {
						this.isLoading = true;
					} else {
						_.remove(productResponseData, function (element) {
							return element.hierarchy === 'parent';
						});
						let i = 1;
						_.forEach(productResponseData, function (data) {
							i = i + 1;
							data.tlevel = i;
							data.byLevelKey = expandedReqObject.byprodlevel;
							data.treeLevel = params.node.level;
						});
						productResponseData = _.orderBy(productResponseData, 'tlevel', 'asc');
						_.forEach(productResponseData, data => {
							if (data.hierarchy === 'child') {
								data.path = [...params.data.path, data.productLevel];
							} else {
								data.path = [...params.data.path];
								data.hierarchy = parent;
							}
						});

						if (this.excel.length == 0) {
							this.excel = [...this.rowData];
						}

						let index =
							this.excel.findIndex(
								item => item.productLevel === productResponseData[0].path.at(-2)
							) + 1;

						this.excel.splice(index, 0, ...productResponseData);

						this.excel.forEach((item, index) => {
							if (item.rowId != undefined) {
								item.rowId = index;
							}

							if (item.path.length > 1) {
								item.treeLevel = item.path.length - 1;
							}
						});

						this.rowData = [...this.rowData, ...productResponseData];
						this.excelProdData.emit(this.excel);
						this.isLoading = false;
					}
				});
		} else {
			this.isLoading = true;
		}
	}

	getDefaultProduct() {
		let defaultCol = this.shareReportService.expandPeriodFact(
			this.selectedPeriod,
			this.selectedFact
		);

		defaultCol.forEach(ele => {
			this.defaultColumns.push(ele);
		});
	}

	getFactColumnDefinition() {
		let facts = null;
		facts = this.selectors.actualValue;
		let factMap: Map<String, any[]> = this.getFactMap();

		let colDefinition: any[] = [];

		facts.forEach(fact => {
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[0] != null &&
				fact.nameDollars === factMap.get(fact.value)[0].name
			)
				colDefinition.push(factMap.get(fact.value)[0]);
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[1] != null &&
				fact.nameDollars === factMap.get(fact.value)[1].name
			)
				colDefinition.push(factMap.get(fact.value)[1]);
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[2] != null &&
				fact.nameDollars === factMap.get(fact.value)[2].name
			)
				colDefinition.push(factMap.get(fact.value)[2]);

			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[3] != null &&
				fact.nameUnits === factMap.get(fact.value)[3].name
			)
				colDefinition.push(factMap.get(fact.value)[3]);
			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[4] != null &&
				fact.nameUnits === factMap.get(fact.value)[4].name
			)
				colDefinition.push(factMap.get(fact.value)[4]);
			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[5] != null &&
				fact.nameUnits === factMap.get(fact.value)[5].name
			)
				colDefinition.push(factMap.get(fact.value)[5]);

			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[6] != null &&
				fact.nameEQ === factMap.get(fact.value)[6].name
			)
				colDefinition.push(factMap.get(fact.value)[6]);
			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[7] != null &&
				fact.nameEQ === factMap.get(fact.value)[7].name
			)
				colDefinition.push(factMap.get(fact.value)[7]);
			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[8] != null &&
				fact.nameEQ === factMap.get(fact.value)[8].name
			)
				colDefinition.push(factMap.get(fact.value)[8]);
		});
		return colDefinition;
	}
	getFactMap(): Map<String, any[]> {
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';

		let expandedBaseFacts: any = [];
		let factData: any = [];
		factData = this.upcColumns;

		let factDataMap: Map<String, any[]> = new Map<string, any[]>();

		this.selectedPeriod.forEach(period => {
			factData.forEach(baseFact => {
				if (!_.eq(baseFact.gkey, 'Product')) {
					if (_.isMatch(baseFact.availablePeriods, period.gkey)) {
						let expandedBaseFact: any = cloneDeep(baseFact);

						expandedBaseFact.name = _.replace(
							expandedBaseFact.name,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);

						expandedBaseFact.headerName = _.replace(
							expandedBaseFact.headerName,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.field = _.replace(
							expandedBaseFact.field,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.period = _.replace(
							expandedBaseFact.period,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.headerTooltip = _.replace(
							expandedBaseFact.headerTooltip,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.cellTooltip = _.replace(
							expandedBaseFact.cellTooltip,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);

						delete expandedBaseFact.availablePeriods;
						expandedBaseFacts.push(expandedBaseFact);
					}
				} else if (!_.find(expandedBaseFacts, baseFact)) {
					expandedBaseFacts.push(baseFact);
				}
			});
		});

		//Forming 3*n array from the column specifications
		expandedBaseFacts.forEach(fact => {
			if (factDataMap.has(fact.gkey)) {
				if (fact.valuebase === 'dollar' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[0] = fact;
				}
				if (fact.valuebase === 'dollar' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[1] = fact;
				}
				if (fact.valuebase === 'dollar' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[2] = fact;
				}
				if (fact.valuebase === 'units' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[3] = fact;
				} else if (fact.valuebase === 'units' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[4] = fact;
				} else if (fact.valuebase === 'units' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[5] = fact;
				}
				if (fact.valuebase === 'EQ' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[6] = fact;
				} else if (fact.valuebase === 'EQ' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[7] = fact;
				} else if (fact.valuebase === 'EQ' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[8] = fact;
				}
			} else if (fact.valuebase === 'dollar' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'dollar' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'dollar' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'units' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'units' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'units' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else {
				factDataMap.set(fact.gkey, [null, null, fact]);
			}
		});

		return factDataMap;
	}

	loadFactDropdown() {
		this.actualValue.push({
			value: 'Product',
			nameDollars: 'Product',
			nameUnits: null,
			isCheckedDollar: null,
			isCheckedUnit: null,
			isCheckedEQ: null,
			isDollarPinned: null,
			isUnitPinned: null,
			isEQPinned: null
		});
		this.selectors.dropdownItems.push(this.actualValue);
		this.selectors.actualValue = [];
		this.actualValue.forEach(fact => {
			if (fact.isCheckedDollar || fact.isCheckedUnit || fact.isCheckedEQ || fact.isCheckedOther)
				this.selectors.actualValue.push(fact);
		});
	}

	loadColumnDefinition(periodFactSelected) {
		this.actualValue = [];
		this.selectedPeriod = [];
		this.actualValue = [...periodFactSelected.finalData];
		this.selectedPeriod = [...periodFactSelected.periods];
		this.loadFactDropdown();
		this.colDefs = this.getFactColumnDefinition();
		this.excelData.emit(this.colDefs);
	}

	printSortColoumn(event) {
		let nameCol;
		let sortOrder;
		let nameOfField;
		let columnWithSort = this.gridApi.api.getColumnState().find(col => col.sort !== null);
		if (columnWithSort) {
			if (columnWithSort.colId === 'ag-Grid-AutoColumn') {
				nameCol = 'Products';
				nameOfField = 'productLevel';
			} else {
				this.colDefs.filter(ccolName => {
					if (ccolName.field === columnWithSort.colId) {
						nameCol = ccolName.headerName;
						nameOfField = ccolName.field;
					}
				});
			}
			sortOrder = columnWithSort.sort;
			this.sortDetails.emit({ nameCol, sortOrder, nameOfField });
		} else {
			sortOrder = '';
			nameCol = '';
			nameOfField = '';
			this.sortDetails.emit({ nameCol, sortOrder, nameOfField });
		}
	}
}
