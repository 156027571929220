<div class="detailed-facts-view">
	<div class="col-lg-11 col-md-11 col-sm-11 col-xs-11">
		<h3>
			{{ walmartSide }} UPCs at Walmart & {{ rmSide }} UPCs in
			<span>Remaining Markets</span>
		</h3>
	</div>
	<div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
		<div class="expand-icon-fact" (click)="showInDetailData()" title="Return to Assortment 4X4">
			<span class="expand-column"
				><img src="assets/img/niq-icons/contract-icon_black.svg" class="expand-img" alt="expand"
			/></span>
		</div>
	</div>
	<div class="clearfix spacer-20"></div>
	<div class="dashboard-secondary-tabs-nav dashboard-secondary-tabs-nav-wmrm tabs-overflow">
		<nav class="navbar">
			<div id="rm-tabs-list-div" class="rm-tabs">
				<ul class="nav nav-tabs navbar-nav">
					<li
						class="product"
						*ngFor="let tab of tabsList; let i = index"
						[ngClass]="{ active: tabIsActive(tab) }"
						(click)="loadWmRmUPCData(tab)"
					>
						<a data-toggle="tab" [id]="i">{{ tab.value }}</a>
					</li>
				</ul>
			</div>
			<div style="float: right; margin-top: 5px">
				<span *ngIf="sort">
					<i
						[class]="sort === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"
						aria-hidden="true"
					></i>
					{{ headerName }}
				</span>
			</div>
		</nav>
	</div>
	<div>
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 inner-table">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
				<div role="tabpanel">
					<div class="rm-pop-table-full-length">
						<div class="scroll_div_WmRm scroll custom">
							<div *ngIf="isLoading">
								<div class="selectorspinner">
									<span class="spinner-large">
										<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
									</span>
								</div>
							</div>
							<crf-ag-grid
								*ngIf="!isLoading"
								#grid
								[id]="activeTab.value"
								[columns]="colDefs"
								[rowData]="rowData"
								[pinnedTopRowData]="pinnedTopRowData"
							></crf-ag-grid>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
