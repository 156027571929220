import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';

@Component({
	selector: 'crf-ca-market-filter',
	templateUrl: './market-filter.component.html',
	styleUrl: './market-filter.component.scss'
})
export class MarketFilterComponent implements OnChanges {
	@Input() marketItems: { value: string; id: number; isSelected: boolean }[];
	@Output() onApply = new EventEmitter<any>();
	@Output() onCancel = new EventEmitter<any>();

	searchPlaceholder = 'Filter Parent Companies';
	filteredMarketItems = [];
	searchQuery = '';
	batchSize = 20;
	currentPage = 0;
	isApplyDisable = true;
	selectedMarket: any = [];

	constructor(private filterPipe: FilterPipe) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.currentPage = 0;
		this.filteredMarketItems = this.marketItems.slice(0, this.batchSize);
		let defaultvalue = this.filteredMarketItems.filter(item => item.isSelected);
		this.selectedMarket.push(defaultvalue[0]);
	}

	onSearchChange(query: string) {
		this.searchQuery = query;
		this.currentPage = 0;
		this.filteredMarketItems = this.marketItems
			.filter(item => item.value.toLowerCase().includes(query.toLowerCase()))
			.map(item => {
				const highlightedValue = this.filterPipe.highlightSearchText(item.value, query);
				return { ...item, highlightedValue };
			});
	}

	clearProductSearch(event) {
		this.searchQuery = '';
		this.filteredMarketItems = this.marketItems.slice(0, this.batchSize);
	}

	trackMarketData(index: number, item: any) {
		return item;
	}

	onScroll(event: any) {
		const scrollPosition = event.target.scrollTop + event.target.offsetHeight;
		const scrollHeight = event.target.scrollHeight;

		if (scrollPosition >= scrollHeight - 5) {
			this.fetchNextBatch();
		}
	}

	fetchNextBatch() {
		const start = this.currentPage * this.batchSize;
		const end = start + this.batchSize;
		const newItems = this.marketItems.slice(start, end);
		const filteredNewItems = newItems.filter(item =>
			item.value.toLowerCase().includes(this.searchQuery.toLowerCase())
		);
		this.filteredMarketItems = [...new Set([...this.filteredMarketItems, ...filteredNewItems])];
		this.currentPage++;
	}
	checkSelection(event) {
		let indexOfElement: number = this.selectedMarket.findIndex(element => element.id === event.id);
		let indexInMarketList: number = this.marketItems.findIndex(element => element.id === event.id);
		if (!event.isSelected) this.selectedMarket.splice(indexOfElement, 1);
		else this.selectedMarket.push(event);

		this.marketItems[indexInMarketList].isSelected = event.isSelected;

		this.isApplyDisable = !this.selectedMarket.some(x => x.isSelected);
	}

	onApplyRequest() {
		const selectedValues = this.selectedMarket.map(item => item.value);
		this.onApply.emit(selectedValues);
	}

	onCancelRequest() {
		this.onCancel.emit();
	}
}
